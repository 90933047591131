<template>
    <v-theme-provider dark>
        <base-section id="sales-conctact" class="text-center" space="56">
            <base-icon class="mb-8">mdi-phone</base-icon>

            <base-section-heading color="white" title="Mobile Number"
                >You can Contact our Sales Manager on this phone
                line</base-section-heading
            >

            <a
                class="d-inline-block mb-8"
                href="#"
                style="text-decoration: none"
                target="_blank"
            >
                <base-section-heading
                    color="transparent"
                    title="+234 703 433 7094"
                ></base-section-heading
            ></a>

            <div class="py-4" />

            <v-btn
                class="font-weight-bold"
                color="white"
                href="https://twitter.com/sylvaleoglobal"
                light
                min-width="168"
                target="_blank"
                x-large
            >
                +234 703 433 7094
                <v-icon right>mdi-phone</v-icon>
            </v-btn>
        </base-section>
    </v-theme-provider>
</template>

<script>
export default {
    name: 'SectionNews',

    provide: {
        heading: { align: 'center' },
    },
};
</script>
<style scoped>
#sales-conctact {
    background-color: #0b2571;
}
</style>
